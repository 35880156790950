import * as React from "react";
import HcpLayout from "../components/layout";
import { Seo } from "../components/seo";
import "./understanding-dpn.scss";
import Banner from "../components/banner";
import bannerImg from "../images/understanding/understanding-banner.jpg";
import bannerImgMobile from "../images/understanding/understanding-banner-mobile.png";
import { Col, Row } from "react-grid-system";
import Button from "../components/button";
import buttonExternalIcon from "../images/icons/external-icon.svg";
import TwoCol from "../components/twoCol/twoCol";
import twoColImg1 from "../images/understanding/a-lady-with-laptop.jpg";
import twoColImg2 from "../images/understanding/a-man-watering-the-plant.jpg";
import twoColImg3 from "../images/understanding/pain-symptom.jpg";
import twoColImg4 from "../images/understanding/a-lady-riding-a-bike.jpg";
import donaMarieThumbnail from "../images/understanding/dona-marie-thumbnail.jpg";
import CardDownload from "../components/cards/cardDownload";
import TileIcon from "../components/tileIcon";
import messageIcon from "../images/icons/message-icon.svg";
import rightIcon from "../images/icons/right-arrow.svg";
import StickyButton from "../components/stickyButton";
import ExternalLink from "../components/modals/externalLink";
import VideoModal from "../components/modals/video-modal";

const TranscriptQutenzaApplication = () => {
  return (
    <>
      <h2>Meet Dona-Marie, a real patient.</h2>
      <p className="bold no-btm-spacer">Voice Over (V.O.):</p>
      <p>
        Pain in my feet feels like I’m walking on fire. And then at the same
        time, this fire is over a needle bed. It’s all-consuming. It prohibits
        me from walking, having to think of how far I can go. During the day,
        there was distraction from what was going on with my feet, but when it
        came time for rest, to go to bed, there were no distractions, so there
        it was, thump, thump, thump. The pain in my feet was due to diabetic
        nerve pain, diabetic neuropathy. That’s serious stuff.
      </p>
      <p>
        My daughter had heard about this foot store that does fittings of shoes.
        They can fit and mold to your feet. So, OK. I’m open. Anything for
        comfort. I got my first pair. I didn’t want to tell her they really
        didn’t help that much. They did help some, but not as much as she was
        hoping. So, I had gotten another pair. This time it was a different
        style. I’d wear them more than 5 minutes, and the pain was just
        overwhelming.
      </p>
      <p>
        My physician is really a good listener. Listens to what your pains and
        aches and everything is, and then he tries to put it together. He took
        in other things that were going on, but especially he was listening to
        me about my feet. I like the way he explained things. It made sense. So
        when he told me the balance issues and the pain and the numbness and the
        tingling was neuropathy, it was sort of a relief.
      </p>
      <p>
        I’m counting on having days where I can be with my grandkids and I don’t
        have to make excuses that I can’t do it because my feet won’t allow me
        to do it. I can do this and I can do that.
      </p>
    </>
  );
};

const UnderstandingDpnPage = () => {
  return (
    <HcpLayout pageClass="understanding-dpn-page">
      <section className="hero-banner">
        <Banner
          extraClass="gradient-overlay"
          bannerImg={bannerImg}
          bannerImgMobile={bannerImgMobile}
          bannerImgAlt="Woman sitting on a dock overlooking a lake and trees"
          bannerTitle="Understanding diabetic nerve pain of the feet"
          nextSecId="#iunderstanding-info"
        />
        <StickyButton />
      </section>

      <section className="info-block" id="iunderstanding-info">
        <div className="centered-content">
          <div className="content">
            <h2 className="h1">Living with painful feet isn’t easy.</h2>
            <Row>
              <Col lg={6}>
                <p>
                  If you’re diagnosed with diabetes and are suffering from foot
                  pain, you may have diabetic nerve pain. Not understanding the
                  cause of your pain can feel frustrating. Getting a clear
                  diagnosis and learning how to treat foot pain can also be
                  challenging. You’re not alone – many people living with
                  diabetic nerve pain of the feet face these same issues.
                </p>
              </Col>
              <Col lg={6}>
                <p>
                  There are steps you can take to help get back on track. Start
                  by educating yourself and talking to your healthcare team.
                  Then, work with your doctor to identify the right treatment
                  for you, as you begin to step away from being defined by
                  diabetic nerve pain of the feet – and toward doing the things
                  you want to do.
                </p>
              </Col>
            </Row>
            <div className="btn-wrap">
              <ExternalLink
                title="Discover a Targeted Treatment"
                extraClass="modal-lg"
                href="https://www.qutenza.com/dpn/about-qutenza/"
              >
                <Button
                  className="btn-primary btn-lg border-36"
                  btnTitle="Discover a Targeted Treatment"
                  btnIcon={buttonExternalIcon}
                />
              </ExternalLink>
            </div>
          </div>
        </div>
      </section>

      <section className="two-col__block">
        <div className="centered-content">
          <TwoCol
            extraClass="align-center"
            image={twoColImg1}
            imageAlt="Woman working on her laptop in her living room with a cup of tea"
            heading="What is diabetic nerve pain of the feet?"
            description={`<p>Nerve damage can happen over time when your blood sugar is high due to diabetes. Too much sugar in the bloodstream can contribute to the nerves in your feet not working as they should. These damaged, unhealthy nerves send too many pain signals to your brain, resulting in the pain you feel in your feet. This can feel like foot numbness or shooting, stabbing, or burning pain. This may affect people in different ways, and it can occur in a very specific part of the foot, or even impact the entire foot.</p>`}
          ></TwoCol>
        </div>
      </section>

      <section className="cta-download-block">
        <div className="centered-content">
          <CardDownload
            title="Diabetic Nerve Pain Infographic"
            desc={`<p>See what patients like you are saying about diabetic nerve pain <nobr>of the feet.</nobr></p>`}
            downloadBtnUrl="/pdf/diabetic-nerve-pain-infographic.pdf"
          />
        </div>
      </section>

      <section className="two-col__block">
        <div className="centered-content">
          <TwoCol
            extraClass="align-center reverse"
            image={twoColImg2}
            imageAlt="Man standing in his garden watering his plants with a hose "
            heading="Why do I have diabetic nerve pain of the feet?"
            description={`<p>Diabetic nerve pain of the feet is one of the most common effects of diabetes. Of 35 million people in the US who are living with diabetes, about 5 million also experience diabetic nerve pain of the feet. Risk factors include:</p>`}
            className="tag-right"
          >
            <div className="dot-list">
              <ul>
                <li>Having a more advanced age</li>
                <li>Being overweight</li>
                <li>Having high cholesterol</li>
                <li>Smoking</li>
                <li>Consuming alcohol</li>
              </ul>
            </div>
          </TwoCol>
        </div>
      </section>

      <section className="video-block">
        <div className="centered-content">
          <VideoModal
            className="video-thumb-lg"
            url={"i44zisg1hw"}
            thumbnail={donaMarieThumbnail}
            thumbnailAlt="Female patient smiling while sitting for an interview"
            title="“The pain, numbness, and tingling was neuropathy.”"
            authorName="Dona-Marie, living with DPN of the feet."
          >
            <TranscriptQutenzaApplication />
          </VideoModal>
        </div>
      </section>

      <section className="two-col__block">
        <div className="centered-content">
          <TwoCol
            extraClass="align-center"
            image={twoColImg3}
            imageAlt="Individual wearing jeans and socks massaging their foot"
            description={`<h2>Your pain.<br>Your symptoms.</h2><p>Symptoms of diabetic nerve pain of the feet can range from tingling or burning sensations to shooting or stabbing pains. You may also experience numbness or not be able to feel pain or temperature changes. If you have diabetic nerve pain of the feet, you may have difficulty walking or lose your balance due to your foot numbness. Symptoms can be different for everyone.</p>`}
            className="tag-left-mobile"
          ></TwoCol>
        </div>
      </section>

      <section className="cta-download-block">
        <div className="centered-content">
          <CardDownload
            title="Doctor Discussion Guide"
            desc={`<p>This guide will help you start a conversation with your doctor about your pain and how you can get relief.</p>`}
            downloadBtnUrl="/pdf/unbranded-doctor-discussion-guide.pdf"
          />
        </div>
      </section>

      <section className="two-col__block">
        <div className="centered-content">
          <TwoCol
            extraClass="align-center reverse"
            image={twoColImg4}
            imageAlt="Woman riding her bike at sunset while her dog walks beside her"
            heading={`A direct way to relieve diabetic nerve pain of the feet`}
            description={`<p>Learn about a unique diabetic foot pain treatment. </p>`}
            className="tag-right"
          >
            <div className="btn-wrap">
              <ExternalLink
                title="Discover a Targeted Treatment"
                extraClass="modal-lg"
                href="https://www.qutenza.com/dpn/about-qutenza/"
              >
                <Button
                  className="btn-primary btn-md border-32"
                  btnTitle="Discover a Targeted Treatment"
                  btnIcon={buttonExternalIcon}
                />
              </ExternalLink>
            </div>
          </TwoCol>
        </div>
      </section>

      <section className="target-block">
        <div className="centered-content">
          <Row className="row">
            <Col lg={6} className="col-left">
              <TileIcon
                url="/get-updates/"
                icon={messageIcon}
                title="Get Updates"
                desc={`<p>Sign up to get educational and personalized resources you can share with your family, friends, and healthcare provider.</p>
                            `}
              />
            </Col>
            <Col lg={6} className="col-right">
              <TileIcon
                extraClass="tile-icon--alt"
                url="/treatment-options/"
                icon={rightIcon}
                title="Treatment Options"
                desc={`<p>If you’re living with diabetic nerve pain of the feet, various foot pain treatments are available.</p>
                            `}
              />
            </Col>
          </Row>
        </div>
      </section>
    </HcpLayout>
  );
};

export default UnderstandingDpnPage;

export const Head = () => <Seo title="Understanding DPN" />;
